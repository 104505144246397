
  import { Vue, Component } from "vue-property-decorator";

  import {
    readDashboardMiniDrawer,
    readDashboardShowDrawer,
    readHasAdminAccess,
    readUserProfile,
  } from "@/store/main/getters";
  import store from "@/store/index";
  import { UserRole } from "@/interfaces";
  import {
    commitSetDashboardShowDrawer,
    commitSetDashboardMiniDrawer,
  } from "@/store/main/mutations";
  import { dispatchUserLogOut } from "@/store/main/actions";
  import { featureFlagSast } from "@/env";

  const routeGuardMain = async (to, from, next) => {
    const userProfile = readUserProfile(store);
    if (!userProfile?.is_active) {
      next("/confirm");
    } else if (!userProfile?.is_superuser && userProfile?.requires_course) {
      next("/events?required=true");
    } else if (to.path === "/main") {
      next("/main/dashboard");
    } else {
      next();
    }
  };

  @Component
  export default class Main extends Vue {
    public beforeRouteEnter(to, from, next) {
      routeGuardMain(to, from, next);
    }

    public beforeRouteUpdate(to, from, next) {
      routeGuardMain(to, from, next);
    }

    get userProfile() {
      return readUserProfile(this.$store);
    }

    get miniDrawer() {
      return readDashboardMiniDrawer(this.$store);
    }

    get showDrawer() {
      return readDashboardShowDrawer(this.$store);
    }

    set showDrawer(value) {
      commitSetDashboardShowDrawer(this.$store, value);
    }

    get isProvider() {
      return (
        this.userProfile &&
        [UserRole.admin, UserRole.practitioner].includes(this.userProfile.role)
      );
    }

    get isSastEnabled() {
      return featureFlagSast;
    }

    public switchShowDrawer() {
      commitSetDashboardShowDrawer(this.$store, !readDashboardShowDrawer(this.$store));
    }

    public switchMiniDrawer() {
      commitSetDashboardMiniDrawer(this.$store, !readDashboardMiniDrawer(this.$store));
    }

    public get hasAdminAccess() {
      return readHasAdminAccess(this.$store);
    }

    public async logout() {
      await dispatchUserLogOut(this.$store);
    }
  }
